<template>
  <div class="d-flex justify-content-center align-items-center vh-100">
    <div class="text-center">
      <coolLogo style="max-height: 50vh"></coolLogo>
      <loadingBlock class=""></loadingBlock>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import loadingBlock from '@/components/loadingBlock.vue';
import coolLogo from '@/components/coolLogo.vue';
export default {
  name: 'HomeView',
  components: {
    loadingBlock,
    coolLogo,
  },
};
</script>

<style>
body {
  background-color: #02030f !important;
}
</style>
