<template>
  <div class="d-flex flex-column justify-content-center pt-5">
    <div class="fs-1">
      <div class="font-jersey waviy row justify-content-center m-0">
        <div class="col-md-auto">
          <span style="--i: 1">B</span>
          <span style="--i: 2">i</span>
          <span style="--i: 3">g </span>
          <span style="--i: 4">&nbsp;</span>

          <span style="--i: 5">T</span>
          <span style="--i: 6">h</span>
          <span style="--i: 7">i</span>
          <span style="--i: 8">n</span>
          <span style="--i: 9">g</span>
          <span style="--i: 10">s</span>
        </div>
        <div class="col-md-auto">
          <span style="--i: 11">C</span>
          <span style="--i: 12">o</span>
          <span style="--i: 13">m</span>
          <span style="--i: 14">i</span>
          <span style="--i: 15">n</span>
          <span style="--i: 16">g</span>
          <span style="--i: 17">!</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'loadingBlock',
};
</script>

<style>
@import url('https://fonts.googleapis.com/css2?family=Jersey+10&family=Lobster&display=swap');

.font-lobster {
  font-family: 'Lobster', sans-serif;
  font-weight: 400;
  font-style: normal;
}
.font-jersey {
  font-family: 'Jersey 10', sans-serif;
  font-weight: 400;
  font-style: normal;
}
.waviy {
  position: relative;
  -webkit-box-reflect: below -20px linear-gradient(transparent, rgba(0, 0, 0, 0.2));
  font-size: 60px;
}
.waviy span {
  position: relative;
  display: inline-block;
  color: #ffffff;
  animation: waviy 3s infinite;
  animation-delay: calc(0.1s * var(--i));
}
@keyframes waviy {
  0%,
  20%,
  100% {
    transform: translateY(0);
  }
  50%,
  80% {
    transform: translateY(-20px);
  }
}
</style>
