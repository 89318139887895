<template>
  <!-- Canvas element to render the 3D scene -->
  <canvas ref="container" class="container-fluid"></canvas>
</template>

<script>
import * as THREE from 'three';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js';
import { OBJLoader } from 'three/examples/jsm/loaders/OBJLoader';

import HolographicMaterial from './HolographicMaterialVanilla.js';

export default {
  name: 'coolLogo',
  props: {},
  mounted() {
    // Get the canvas container reference
    const container = this.$refs.container;

    // Create a new Three.js scene
    const scene = new THREE.Scene();

    // Set screen resolution based on container size
    const screenRes = {
      width: container.clientWidth,
      height: container.clientHeight,
    };

    // Create a perspective camera
    const camera = new THREE.PerspectiveCamera(
      35,
      screenRes.width / screenRes.height,
      0.1,
      10000
    );
    camera.position.set(0, 0, 4);
    scene.add(camera);

    // Add orbit controls to the camera
    const controls = new OrbitControls(camera, container);
    controls.enableDamping = true;
    controls.maxDistance = 6;
    controls.minDistance = 2;
    controls.maxPolarAngle = Math.PI / 1.7;
    controls.minPolarAngle = 1.1;
    controls.autoRotate = true;
    controls.autoRotateSpeed = 3;

    // Add directional light to the scene
    const light = new THREE.DirectionalLight();
    light.intensity = 1;
    light.position.set(-20, 20, 50);
    scene.add(light);

    // Add ambient light to the scene
    const ambientLight = new THREE.AmbientLight();
    ambientLight.intensity = 2.9;
    scene.add(ambientLight);

    // Create a WebGL renderer
    const renderer = new THREE.WebGLRenderer({
      canvas: container,
      powerPreference: 'high-performance',
      antialias: false,
      stencil: false,
      depth: false,
      alpha: true,
    });
    renderer.outputColorSpace = THREE.LinearSRGBColorSpace;
    renderer.toneMapping = THREE.LinearToneMapping;
    renderer.toneMappingExposure = 0.7;
    renderer.setSize(screenRes.width, screenRes.height);
    renderer.setPixelRatio(Math.min(window.devicePixelRatio, 1));

    // Create a holographic material
    const holographicMaterial = new HolographicMaterial();

    // Load the 3D model
    var model;
    const loader = new OBJLoader();
    loader.load('emas.obj', (obj) => {
      model = obj;
      model.traverse((child) => {
        if (child instanceof THREE.Mesh) {
          child.material = holographicMaterial;
        }
      });
      scene.add(model);
    });

    // Handle window resize event
    window.addEventListener('resize', () => {
      screenRes.width = container.clientWidth;
      screenRes.height = container.clientHeight;

      camera.aspect = screenRes.width / screenRes.height;
      camera.updateProjectionMatrix();

      renderer.setSize(screenRes.width, screenRes.height);
      renderer.setPixelRatio(Math.min(window.devicePixelRatio, 1));
    });

    // Update the holographic material and request animation frame
    const tick = () => {
      holographicMaterial.update(); // Update the holographic material time uniform
      window.requestAnimationFrame(tick);
    };

    tick();

    // Animate the scene
    const animate = () => {
      controls.update();
      renderer.render(scene, camera);
      requestAnimationFrame(animate);
    };

    animate();
  },
};
</script>
